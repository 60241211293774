<template>
   <div class="subBg">
      <section>
        <div class="snavi">
          <h2 class="subtxt"><img src="@/assets/img/icon_star.svg" />{{ $t('front.common.cscenter') }}</h2>
        </div>
        <div class="boardwrap">
          <nav-side :currentMenu="currentName" :menuList="menuList" />
          <router-view />
        </div>
      </section>
   </div>
</template>

<script>
import NavSide from '@/components/common/NavSide'

export default {
  name: 'CsCenter',
  components: {
    NavSide
  },
  data () {
    return {
      currentName: '',
      breadcrumbText1: '',
      breadcrumbText2: '',
      menuList: []
    }
  },
  watch: {
    $route: {
      handler () {
        this.getCurrentName()
      }
    }
  },
  created () {
    this.getCurrentName()
    this.setMenuList()
  },
  methods: {
    getCurrentName () {
      this.breadcrumbText1 = ''
      this.breadcrumbText2 = ''
      if (this.$route.matched) {
        const count = this.$route.matched.length === 4 ? 2 : 1
        const item = this.$route.matched[this.$route.matched.length - count]
        if (item) {
          this.currentName = item.name
        }
        this.breadcrumbText1 = item.meta.text
        console.log(item.meta.text)
        const currentMenuText = this.$route.matched[this.$route.matched.length - 1].meta.text
        console.log(currentMenuText)
        if (this.breadcrumbText1 !== currentMenuText) {
          this.breadcrumbText2 = currentMenuText
        }
      }
    },
    setMenuList () {
      const list = this.$router.getRoutes()

      for (let i = 0, iLen = list.length; i < iLen; i++) {
        const item = list[i]
        const routeName = item.name
        if (routeName === 'csCenter') {
          const children = item.children
          for (let j = 0, jLen = children.length; j < jLen; j++) {
            const subItem = children[j]
            if (subItem.meta.lnb) {
              this.menuList.push(subItem)
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
